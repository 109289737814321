import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Table,
    CardTitle,
    Button,
} from "reactstrap";
import { get } from "helpers/api_helper";
import SweetAlert from "react-bootstrap-sweetalert";

//i18n
import { withTranslation } from "react-i18next";
import ModalDelegasiStudent from "./ModalDelegasiStudent";
import LoadingSpinner from "components/Common/LoadingSpinner";
import Pagination from "pages/Master-Data/Component/Student/Pagination";

//redux
// import { useSelector, useDispatch } from "react-redux";

const Siswa = props => {
    const [modal, setmodal] = useState(false);
    const [idClass, setIdClass] = useState(null);
    // Table Data
    const [ dataSiswa, setDataSiswa ] = useState([]);
    const [ dataSiswaFiltered, setDataSiswaFiltered ] = useState([]);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ dataPerPage, setDataPerPage ] = useState(10);
    const [ filter,setFilter ] = useState({key:''});
    const [ isLoading, setIsloading ] = useState(false);
    const [ error_alert, seterror_alert ] = useState(false);

    const getData = async ()=> {
      setIsloading(true);
      try {
        let response = await get("/class/all");
        let data = response.data;
        const map = data.map((item, index) => {
            let id = item.id;
            let kelas = item.name;
            let grade = item.level_number;
            let jumlahSiswa = item.pickup.total_absen + '/' + item.total_siswa;
            let keyExtractor = `${kelas}-${grade}-${jumlahSiswa}`.toLowerCase();
            return { id: id, kelas: kelas, grade: grade, jumlahSiswa: jumlahSiswa, keyExtractor: keyExtractor };
        });
        const sortedData = map.sort((a,b)=>a.id - b.id);
        setDataSiswa(sortedData);
        setDataSiswaFiltered(sortedData);
      } catch (error) {
        seterror_alert(true);
      }
      setIsloading(false);
    }

    useEffect (()=>{
        getData();
    }, []);

    useEffect(()=>{
        setCurrentPage(1);
        const newData = dataSiswa.filter(item => {
          return ((filter?.key) ? (item.keyExtractor.includes(filter.key.toLowerCase())) : true);
        })
        setDataSiswaFiltered(newData)
    },[filter]);

    const toggleModal = (id = null) => {
        if(!modal) { setIdClass(id); }
        else { setIdClass(null); }
        setmodal(!modal);
    }

    const header = ["Class","Grade","Number of Students","Action"];

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = dataSiswaFiltered.slice(indexOfFirstData, indexOfLastData);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return ( 
        <div>
            <Row>
                <Col className="col-12">
                    <CardTitle className="h4">Student Delegation</CardTitle>

                    <Row className="my-2">
                      <Col md="12" className="mt-2">
                        <div className="search-box me-2 mb-3 d-flex justify-content-end">
                          <div className="position-relative ">
                            <input className="form-control" type="text" placeholder="Search" value={filter.key} onChange={item=> setFilter({...filter,key:item.target.value})}/>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className='table-responsive'>
                      <Table className='table'>
                        <thead>
                            <tr>
                              {header.map((x, index) => (
                                <th key={`head-${index}`}>{x}</th>
                              ))}
                            </tr>
                        </thead>
                        <tbody>
                          {currentData.length > 0 ?
                            isLoading ? 
                              <tr>
                                  <td colSpan={4}><LoadingSpinner/></td>
                              </tr>
                            : 
                            currentData.map((e,index) => {
                                return(
                                  <React.Fragment key={`${e.id}-${index}`}>
                                    <tr>
                                      <td>{e?.kelas || ""}</td>
                                      <td>{e?.grade || ""}</td>
                                      <td>{e?.jumlahSiswa || ""}</td>
                                      <td>
                                        <Button className="btn btn-sm btn-primary" onClick={() => toggleModal(e.id)} >Delegate</Button>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              })
                            :
                            <tr>
                              <td colSpan={4} className="text-center">No Student</td>
                            </tr>
                          }
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      setDataPerPage={setDataPerPage}
                      dataPerPage={dataPerPage}
                      totalData={dataSiswaFiltered.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                </Col>
            </Row>

            <ModalDelegasiStudent modalShow={modal} toggleModal={toggleModal} idClass={idClass} />
            { error_alert ?
                <SweetAlert
                    title="Oppss!"
                    confirmBtnText="Yes"
                    error
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        seterror_alert(false)
                    }}
                >
                    Something wrong, please try again
                </SweetAlert>
            : null }
        </div>
    );
};

Siswa.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(Siswa);
