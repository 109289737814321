import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Table,
} from "reactstrap";
import { get } from "helpers/api_helper";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import "./datatables.scss"

//i18n
import { withTranslation } from "react-i18next";
import { ModalAdd, ModalDetail } from "./Components";
import { useLocation } from "react-router-dom";
import Pagination from "pages/Master-Data/Component/Student/Pagination";
import LoadingSpinner from "components/Common/LoadingSpinner";
import SweetAlert from "react-bootstrap-sweetalert";

//redux
// import { useSelector, useDispatch } from "react-redux";

const Delegasi = props => {
  // Table Data
  const [ dataUser, setDataUser ] = useState([]);
  const [ dataUserFiltered, setDataUserFiltered ] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ dataPerPage, setDataPerPage ] = useState(10);
  const [ filter,setFilter ] = useState({key:''});
  const [ isLoading, setIsloading ] = useState(false);

  const [modalAdd, setmodalAdd] = useState(false);
  const [modalDetail, setmodalDetail] = useState(false);
  const [idDetail, setIdDetail] = useState(null);
  const [error_alert, seterror_alert] = useState(false);

  const toggleModal = () => setmodalAdd(!modalAdd);
  const toggleModalDetail = (id = null) => {
    if(!modalDetail) { setIdDetail(id) }
    else { setIdDetail(null) }
    setmodalDetail(!modalDetail);
  }
 
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search),[search])
  }

  let query = useQuery();

  const getData = async ()=> {
    setIsloading(true);
    try {
      let response = await get("/user");
      let data = response.data;
      const map = data.map((item, index) => {
        let id = item.id
        let namaUser = item.full_name;
        let email = item.email;
        let noTelp = item.phone;
        let username = item.username;
        let keyExtractor = `${id}-${namaUser}-${email}-${username}`.toLowerCase();
        return { id: id , namaUser: namaUser, username:username, email: email, noTelp: noTelp, keyExtractor: keyExtractor };
      });
      
      const sortedData = map.sort((a,b)=>a.id - b.id);
      const searchQuery = query.get('username');
      if(searchQuery){
        const filteredUser = sortedData.filter(x => x.username === searchQuery);
        setDataUser(filteredUser);
        setDataUserFiltered(filteredUser);
      } else {
        setDataUser(sortedData);
        setDataUserFiltered(sortedData);
      }
    } catch (error) {
      seterror_alert(true);
    }
    setIsloading(false);
  }

  useEffect (()=>{
    getData()
  }, []);

  useEffect(()=>{
    setCurrentPage(1);
    const newData = dataUser.filter(item => {
      return ((filter?.key) ? (item.keyExtractor.includes(filter.key.toLowerCase())) : true);
    })
    setDataUserFiltered(newData)
  },[filter]);

  const header = ["id","Full Name","Username","Email","Action"];

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = dataUserFiltered.slice(indexOfFirstData, indexOfLastData);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
            <title>Tzu Chi</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
              title={props.t("User")}
              breadcrumbItem={props.t("User Management")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">User Management</CardTitle>
                  <p className="card-title-desc"></p>

                  <Row className="my-2">
                    <Col md="12" className="mt-2">
                      <div className="search-box me-2 mb-3 d-flex justify-content-end">
                        <div className="position-relative ">
                          <input className="form-control" type="text" placeholder="Search" value={filter.key} onChange={item=> setFilter({...filter,key:item.target.value})}/>
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className='table-responsive'>
                    <Table className='table'>
                      <thead>
                          <tr>
                            {header.map((x, index) => (
                              <th key={`head-${index}`}>{x}</th>
                            ))}
                          </tr>
                      </thead>
                      <tbody>
                        {currentData.length > 0 ?
                          isLoading ? 
                            <tr>
                                <td colSpan={5}><LoadingSpinner/></td>
                            </tr>
                          : 
                          currentData.map((e,index) => {
                              return(
                                <React.Fragment key={`${e.id}-${index}`}>
                                  <tr>
                                    <td>{e?.id || ""}</td>
                                    <td>{e?.namaUser || ""}</td>
                                    <td>{e?.username || ""}</td>
                                    <td>{e?.email || ""}</td>
                                    <td>
                                    <Button className="btn btn-sm btn-primary mx-1" onClick={() => toggleModalDetail(e.id)}>Detail</Button>  
                                    </td>
                                  </tr>
                                </React.Fragment>
                              )
                            })
                          :
                          <tr>
                            <td colSpan={5} className="text-center">No Data</td>
                          </tr>
                        }
                      </tbody>
                    </Table>
                  </div>
                  <Pagination
                    setDataPerPage={setDataPerPage}
                    dataPerPage={dataPerPage}
                    totalData={dataUserFiltered.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <ModalAdd modalShow={modalAdd} toggleModal={toggleModal}/>
        <ModalDetail modalShow={modalDetail} toggleModal={toggleModalDetail} idDetail={idDetail}/>
        { error_alert ?
            <SweetAlert
                title="Oppss!"
                confirmBtnText="Yes"
                error
                confirmBtnBsStyle="primary"
                onConfirm={() => {
                    seterror_alert(false)
                }}
            >
                Something wrong, please try again
            </SweetAlert>
        : null }

      </div>
    </React.Fragment>
  );
};

Delegasi.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Delegasi);
