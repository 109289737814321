import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Badge,
  Card,
  CardBody,
  Table,
} from "reactstrap";

import Select from "react-select";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Pagination from '../Master-Data/Component/Student/Pagination'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import SweetAlert from "react-bootstrap-sweetalert";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import { get, post } from "helpers/api_helper";
import user1 from "../../assets/images/users/avatar-blank.png";
import { API_URL } from "helpers/url_helper";
import LoadingSpinner from "components/Common/LoadingSpinner";
import NoData from "components/Common/NoData";

const Penjemputan = props => {
  const [ students, setStudents ] = useState([]);
  const [ studentsFiltered, setStudentFiltered ] = useState([]);
  const [ grades, setGrades] = useState([{label: "grade", options: [{label:'Select Grade',value:null}]}]);
  const [ classes, setClasses] = useState([{label: "class", options: [{label:'Select Class',value:null}]}]);
  const [ status, setStatus ] = useState([{label: "status", options: [{label:'Select Status',value:null}]}]);
  const [ filter,setFilter ] = useState({grades:null,classes:null,status:null,key:''})
  const [ isLoading, setIsloading ] = useState(false);
  const [ confirm_alert, setconfirm_alert ] = useState(false);
  const [ message_alert, setmessage_alert ] = useState(false);
  const [ error_alert, seterror_alert ] = useState(false);
  const [ idPickup, setIdPickup ] = useState(null);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ dataPerPage, setDataPerPage ] = useState(10);
  const [ expandLoading, setExpandLoading ] = useState(false);
  const [ dataDriver, setDataDriver ] = useState();

  const handlerClickPickup = (id) => {
    setIdPickup(id);
    setconfirm_alert(true);
  } 

  const pisckupStudent = async () => {
    const body = {
      data: [
        {
          student_id: idPickup,
        }
      ]
    }

    try {
      let response = await post(`/pickup`, body);
      if(response) {
        setmessage_alert(true);
        setIdPickup(null);
      }
    } catch (error) {
      seterror_alert(true);
    }
  }

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search),[search])
  }

  let query = useQuery();

  const getData = async ()=> {
    setIsloading(true);
    try {
      let response = await get("/student/all/pickup");
      let rawData = response.data;
      let data = rawData.map((item)=>{
        var status;
        if(item.pickup) {
          if(item.pickup.status == 'accepted') { status = 'Waiting To Be Released' }
          else if(item.pickup.status == 'done') { status = 'Finished' }
          else if(item.pickup.status == 'canceled') { status = 'QR Code Expired' }
        }
        else { status = 'Not Requested' }
        const result = {
          id: item.id,
          va: item.va,
          name: item.name,
          grade: item?.kelas?.split(" ")[0] || "-",
          kelas: item?.kelas?.split(" ")[1] || "-",
          status: status,
          dirverId: item.pickup ? item.pickup.driver_id : null,
          isPickup: item.pickup ? (item.pickup.status == 'canceled' ? false : true ) : false,
          isExpand: false,
          keyExtractor: `${item.va}-${item.name}-${item?.kelas?.split(" ")[0] || "-"}-${item?.kelas?.split(" ")[1] || "-"}-${status}`.toLowerCase()
        }
        return result
      })
      
      const sortedData = data.sort((a,b)=>a.va - b.va);
      setStudents(sortedData || []);
  
      let searchQuery = query.get('status');
      if(searchQuery){
        searchQuery = searchQuery.replaceAll('-', ' ');
      }
      if(searchQuery){
        const filteredUser = sortedData.filter(x => x.status.toLowerCase() == searchQuery);
        setStudentFiltered(filteredUser || []);
        setFilterData(filteredUser)
      } else { 
        setStudentFiltered(sortedData || []);
        setFilterData(sortedData)
      }
    } catch (error) {
      console.error(error);
      seterror_alert(true);
    }

    setIsloading(false);
  }

  const setFilterData = raw => {
    const grade = raw.map(item => item.grade).filter((value,i,array) => array.indexOf(value) === i)
    const kelas = raw.map(item => item.kelas).filter((value,i,array) => array.indexOf(value) === i)
    const status = raw.map(item => item.status).filter((value,i,array) => array.indexOf(value) === i)
    
    let gradeFilterOptions = [{label:'Select Grade',value:null}]
    let classFilterOptions = [{label:'Select Class',value:null}]
    let statusFilterOptions = [{label:'Select Status',value:null}]

    grade.map(item => {
      gradeFilterOptions.push({label:item,value:item});
    });
    kelas.map(item => {
      classFilterOptions.push({label:item,value:item});
    });
    status.map(item => {
      statusFilterOptions.push({label:item,value:item});
    });

    let gradeFilter = {
      label:"grade",
      options:gradeFilterOptions
    }
    let classFilter = {
      label:"class",
      options:classFilterOptions
    }
    let statusFilter = {
      label:"status",
      options:statusFilterOptions
    }
    setGrades([gradeFilter]);
    setClasses([classFilter]);
    setStatus([statusFilter]);
  }

  const clearFilter = () => {
    setFilter({grades:null,classes:null,status:null,key: ''});
  }

  useEffect (()=>{
    getData();
  },[])

  useEffect(()=>{
    setCurrentPage(1);
    const newData = students.filter(item => {
      return ((filter?.grades) ? (filter?.grades === item.grade) : true) && 
      ((filter?.classes) ? (filter?.classes === item.kelas) : true) && 
      ((filter?.status) ? (filter?.status === item.status) : true) && 
      ((filter?.key) ? (item.keyExtractor.includes(filter.key.toLowerCase())) : true)
    })
    setStudentFiltered(newData)
  },[filter]);

  const getDriver = async (id) => {
    setExpandLoading(true);
    try {
      let response = await get(`/user/detail/${id}`);
      setDataDriver({name: response.data.full_name, phone: response.data.phone, photo: response.data.photo_profile, avatarErr: false});
    } catch (error) {
      seterror_alert(true);
    }
    setExpandLoading(false);
  }

  const handleExpandFetcher = (id, driver) => {
    const newData = studentsFiltered.map(e=>{
      if(e.id === id){
          e.isExpand = !e.isExpand;
          getDriver(driver);
      } else {
          e.isExpand = false;
      }
      return e
    });
    setStudentFiltered(newData);
  }

  const header = ["VA","Student Name","Grade","Class","Status"];

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = studentsFiltered.slice(indexOfFirstData, indexOfLastData);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tzu Chi</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Pickup")}
            breadcrumbItem={props.t("Pickup")}
          />
          <Card>
            <CardBody>
              <Row>
                <h5>Pickup</h5>
              </Row>

              <Row className="my-2">
                <Col md="2">
                  <div className="my-2">
                    <Select
                      placeholder="Select Grade"
                      value={grades[0].options.find(obj => obj.value === filter.grades)}
                      options={grades}
                      onChange={item => setFilter({...filter,grades:item.value})}
                    />
                  </div>
                </Col>
                <Col md="2">
                  <div className="my-2">
                    <Select
                      placeholder="Select Class"
                      value={classes[0].options.find(obj => obj.value === filter.classes)}
                      options={classes}
                      onChange={item => setFilter({...filter,classes:item.value})}
                    />
                  </div>
                </Col>
                <Col md="2">
                  <div className="my-2">
                    <Select
                      placeholder="Select Status"
                      value={status[0].options.find(obj => obj.value === filter.status)}
                      options={status}
                      onChange={item => setFilter({...filter,status:item.value})}
                    />
                  </div>
                </Col>
                <Col md="2">
                  <div className="my-2">
                    <Button color="primary" className=" px-4" onClick={()=>clearFilter()}>Clear</Button>
                  </div>
                </Col>
                <Col md="4" className="mt-2">
                  <div className="search-box me-2 mb-3 d-flex justify-content-end">
                    <div className="position-relative ">
                      <input className="form-control" type="text" placeholder="Search" value={filter.key} onChange={item=> setFilter({...filter,key:item.target.value})}/>
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className='table-responsive'>
                <Table className='table'>
                    <thead>
                        <tr>
                          {header.map((x, index) => (
                            <th key={`head-${index}`}>{x}</th>
                          ))}
                        </tr>
                    </thead>
                    <tbody>
                      {currentData.length > 0 ?
                        isLoading ? 
                          <tr>
                              <td colSpan={6}><LoadingSpinner/></td>
                          </tr>
                        : 
                        currentData.map((e,index) => {
                            return(
                              <React.Fragment key={`${e.id}-${index}`}>
                                <tr>
                                  <td><Link to={`/masterdata/student?va=${e.va}&expand=true`}>{e?.va || "N/A"}</Link></td>
                                  <td>{e?.name || "N/A"}</td>
                                  <td>{e?.grade || "N/A"}</td>
                                  <td>{e?.kelas || "N/A"}</td>
                                  <td><Badge className={(e.status == 'Not Requested') ? 'bg-secondary py-1' : (e.status == 'Waiting To Be Released') ? 'bg-info py-1' : 'bg-success py-1'}>{e.status}</Badge></td>
                                  {/* <td>
                                    {e.isPickup ? 
                                      <Button color="secondary" className="btn btn-sm" onClick={()=>handleExpandFetcher(e.id, e.dirverId)}>
                                        <i className={`bx bx-chevron-${e.isExpand ? "down" : "right"}`}/>
                                      </Button> 
                                    : 
                                      <Button color="primary" className="btn btn-sm" onClick={()=> handlerClickPickup(e.id)}>Pickup</Button>
                                    }
                                  </td> */}
                                </tr>
                                {e.isExpand ?
                                    <tr>
                                      <td colSpan={6}>
                                        {expandLoading ?
                                          <LoadingSpinner/>
                                        :
                                        dataDriver ? 
                                          <Row className="justify-content-center">
                                            <h4 className="text-center">Detail Driver</h4>
                                            <Col md="2">
                                              <img 
                                                src={dataDriver.avatarErr ? user1 : `${API_URL + `/` + dataDriver.photo || ""}`} 
                                                onError={() => setDataDriver({...dataDriver, avatarErr:true})}
                                                className="avatar-lg img-fluid rounded" style={{objectFit:"cover"}} 
                                              /> 
                                            </Col>
                                            <Col md="3">
                                              <table className="table">
                                                <tbody>
                                                  <tr>
                                                    <td>Name</td>
                                                    <td>: {dataDriver.name}</td>
                                                  </tr>
                                                  <tr>
                                                    <td>Phone</td>
                                                    <td>: {dataDriver.phone}</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </Col>
                                          </Row>
                                        : null
                                        }
                                      </td>
                                    </tr>
                                : null}
                              </React.Fragment>
                            )
                          })
                        :
                        <tr>
                          <td colSpan={6} className="text-center">No Student</td>
                        </tr>
                      }
                    </tbody>
                </Table>
              </div>
              <Pagination
                setDataPerPage={setDataPerPage}
                dataPerPage={dataPerPage}
                totalData={studentsFiltered.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>

      { confirm_alert ? 
        <SweetAlert
          title= 'Warning!'
          info
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
              pisckupStudent();
              setconfirm_alert(false);
          }}
          onCancel={() => {
            setconfirm_alert(false);
            setIdPickup(null);
          }}
        >
          Are you sure to pickup this student
        </SweetAlert>
      : null }

      { message_alert ?
          <SweetAlert
              title="Success!"
              confirmBtnText="Yes"
              success
              confirmBtnBsStyle="primary"
              onConfirm={() => {
                  getData();
                  setmessage_alert(false);
              }}
          >
              Success to pickup this student
          </SweetAlert>
      : null }

      { error_alert ?
          <SweetAlert
              title="Oppss!"
              confirmBtnText="Yes"
              error
              confirmBtnBsStyle="primary"
              onConfirm={() => {
                  seterror_alert(false)
              }}
          >
              Something wrong, please try again
          </SweetAlert>
      : null }
    </React.Fragment>
  );
};

Penjemputan.propTypes = {
  t: PropTypes.any,
};

const Avatar = (props) => {
  const [avatarErr, setAvatarErr] = useState(false);
  return(
      <img
          src={avatarErr ? user1 : `${API_URL + `/` + props.photo}` }
          onError={() => setAvatarErr(true)}
          className="avatar-sm img-fluid rounded" style={{objectFit:"cover"}}
      />
  )
}

Avatar.propTypes = {
  photo: PropTypes.any
}

export default withTranslation()(Penjemputan);
