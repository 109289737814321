import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal, 
  ModalHeader,
  ModalBody,
  Table,
  FormFeedback,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

import { get, put} from "helpers/api_helper";
import user1 from "../../../../assets/images/users/avatar-blank.png";
import { API_URL } from "helpers/url_helper";

//i18n
import { withTranslation } from "react-i18next";
import { set } from "lodash";
import Pagination from "./Pagination";
import LoadingSpinner from "components/Common/LoadingSpinner";

const ModalEditFamily = (props) => {
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [message_alert, setmessage_alert] = useState(false);
    const [error_alert, seterror_alert] = useState(false);
    const [superFetcher, setSuperFetcher] = useState([]);
    const [bodyFetcher, setBodyFetcher] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [expandLoading, setExpandLoading] = useState(false);
    const [dataSibling, setDataSibling] = useState([]);

    const getDataFetcher = async () => {
        try {
            let response = await get(`/user/all_superfetcher`);
            const data = response.data.map(e => {
                const isExpand = false;
                const keyExtractor = `${e.full_name}-${e.phone}`.toLowerCase()
                return {...e, isExpand, keyExtractor}
            })
            setSuperFetcher(data);
            setFilteredData(data);
        } catch (error) {
            seterror_alert(true);
        }
    }

    useEffect(() => {
        if(props.modalShow === true) { 
            getDataFetcher();
        }
        else { setSuperFetcher([]) }
    }, [props.modalShow]);

    const handlerChange = (data) => {
        const body = {
            family_id: data.family_id,
            super_fetcher_email: data.email,
            super_fetcher_name: data.full_name,
            super_fetcher_phone: data.phone,
            super_fetcher_relationship: data.relationship
        }
        setBodyFetcher(body);
        setconfirm_alert(true);
    }

    const putSuperFetcher = async () => {
        try {
            let response = await put(`/student/update/data?va=${props.Student.va}`, bodyFetcher);
            if(response) {
                setBodyFetcher({});
                setmessage_alert(true);
            }
        } catch (error) {
            seterror_alert(true);
        }
    }

    const handleSearch = entry => {
        const rawData = superFetcher;
        const result = rawData.filter(e => e.keyExtractor.includes(entry.toLowerCase()) );
        setFilteredData(result);
    }

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = filteredData.slice(indexOfFirstData, indexOfLastData);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const handleExpandFetcher = (id) => {
        const newData = superFetcher.map(e=>{
            if(e.id === id){
                e.isExpand = !e.isExpand;
                getDataSibling(e.family_id);
            } else {
                e.isExpand = false;
            }
            return e
        });
        setSuperFetcher(newData);
    }

    const getDataSibling = async (id) => {
        setExpandLoading(true);
        try {
            let responseSibling = await get(`/student/get/data?family_id=${id}`);
            const Sibling = responseSibling.data.student.map(e => {
                return {id: e.id, name: e.name, kelas: e.kelas, va: e.va, photo: e.photo, avatarErr: false }
            })
            setDataSibling(Sibling.filter(x => x.id != props.Student.id));
        } catch (error) {
            seterror_alert(true);
        }
        setExpandLoading(false);
    }

    const handlerAvatarErr = (looping) => {
        const newData = dataSibling.map((e, index) => {
            if(looping == index) {
                return {id: e.id, name: e.name, kelas: e.kelas, va: e.va, photo: e.photo, avatarErr: true }
            }
            else { return e }
        })
        setDataSibling(newData)
      }
   
    return(
        <div>
            <Modal
                size="lg"
                centered={true}
                isOpen={props.modalShow}
                toggle={props.toggleModal}
                scrollable={true}
            >
                <ModalHeader toggle={props.toggleModal}>Move This Student To Other Family</ModalHeader>
                <ModalBody>
                    <Row className="mb-3">
                        <Col lg='6'>
                            <Row className="mb-1">
                                <div className="col-md-4 fw-bolder">Student Name</div>
                                <div className="col-md-8">: {props.Student.name}</div>
                            </Row>
                            <Row>
                                <div className="col-md-4 fw-bolder">Student Va</div>
                                <div className="col-md-8">: {props.Student.va}</div>
                            </Row>
                        </Col>
                        <Col lg='6'>
                            <Row>
                                <Col md="12">
                                    <div className="search-box me-2 mb-3 d-flex justify-content-end">
                                    <div className="position-relative ">
                                        <input className="form-control" type="text" placeholder="Search" onChange={(e)=> handleSearch(e.target.value)}/>
                                        <i className="bx bx-search-alt search-icon" />
                                    </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Full Name</th>
                                        <th>Phone</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { currentData.length > 0 ? 
                                        currentData.map((x, index) => {
                                            return(
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td><Avatar photo={x.photo_profile} /></td>
                                                        <td>{x.full_name}</td>
                                                        <td>{x.phone}</td>
                                                        <td>
                                                            <Button onClick={()=>handleExpandFetcher(x.id)} color="secondary" className="btn btn-sm">
                                                                <i className={`bx bx-chevron-${x.isExpand ? "down" : "right"}`}/>
                                                            </Button>
                                                            <button className="btn btn-danger btn-sm mx-1" onClick={() => handlerChange(x)}>
                                                                set to this family
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    {x.isExpand ?
                                                        <tr>
                                                            <td colSpan={4}>
                                                                {expandLoading ? 
                                                                    <LoadingSpinner />
                                                                : 
                                                                <div className="bg-light rounded py-2">
                                                                    <h5 className="text-center mt-3">Sibling Detail</h5>
                                                                    <Row className="justify-content-center p-2">
                                                                        {dataSibling.map((x, index) => {
                                                                            return(
                                                                                <Col md="4" key={index}>
                                                                                    <Row>
                                                                                        <Col md="5">
                                                                                            <img 
                                                                                                src={x.avatarErr ? user1 : `${API_URL + `/` + x?.photo || ""}`} 
                                                                                                onError={() => handlerAvatarErr(index)}
                                                                                                className="avatar-lg img-fluid rounded" style={{objectFit:"cover"}} 
                                                                                            /> 
                                                                                        </Col>
                                                                                        <Col md="7">
                                                                                            <Table className="table table-borderless table-sm">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td className="card-title-desc">Name</td>
                                                                                                        <td>{x.name}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="card-title-desc">VA</td>
                                                                                                        <td>{x.va}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="card-title-desc">Class</td>
                                                                                                        <td>{x.kelas}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </Table>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            )
                                                                        })}
                                                                    </Row>
                                                                </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    : null}
                                                </React.Fragment>
                                                
                                            )
                                        })
                                    : 
                                        <tr>
                                            <td colSpan={4} className="text-center">No Data Super Fetcher</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <Pagination
                                dataPerPage={dataPerPage}
                                totalData={filteredData.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            
            { confirm_alert ? 
                <SweetAlert
                    title= 'Warning!'
                    info
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        putSuperFetcher();
                        setconfirm_alert(false);
                    }}
                    onCancel={() => {setconfirm_alert(false);}}
                >
                    Are you sure to move this student to other family
                </SweetAlert>
            : null }

            { message_alert ?
                <SweetAlert
                    title="Success!"
                    confirmBtnText="Yes"
                    success
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        setmessage_alert(false);
                        props.toggleModal();
                        props.handleExpand(props.Student.id);
                    }}
                >
                    Success to move this student to other family
                </SweetAlert>
            : null }

            { error_alert ?
                <SweetAlert
                    title="Oppss!"
                    confirmBtnText="Yes"
                    error
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        seterror_alert(false)
                    }}
                >
                    Something wrong, please try again
                </SweetAlert>
            : null }
        </div>
    )
}

const Avatar = (props) => {
    const [avatarErr, setAvatarErr] = useState(false);
    return(
        <img
            src={avatarErr ? user1 : `${API_URL + `/` + props.photo}` }
            onError={() => setAvatarErr(true)}
            className="avatar-sm img-fluid rounded" style={{objectFit:"cover"}}
        />
    )
}

Avatar.propTypes = {
    photo: PropTypes.any
}

ModalEditFamily.propTypes = {
    toggleModal: PropTypes.any,
    modalShow: PropTypes.any,
    Student: PropTypes.any,
    handleExpand: PropTypes.any,
};

export default withTranslation()(ModalEditFamily);