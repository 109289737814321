import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Badge,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import { get, post } from "helpers/api_helper";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import LoadingSpinner from "components/Common/LoadingSpinner";
import NoData from "components/Common/NoData";
import Pagination from "pages/Master-Data/Component/Student/Pagination";

const Information = props => {
  const [ students, setStudents ] = useState([]);
  const [ studentsFiltered, setStudentFiltered ] = useState([]);
  const [ isLoading, setIsloading ] = useState([]);
  const [ filter,setFilter ] = useState({kelas: null, jenis: null, key:''});
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ dataPerPage, setDataPerPage ] = useState(10);
  const [ jenis, setJenis] = useState([{label: "Status", options: [{label:'Select Status',value:null}]}]);
  const [ kelas, setKelas ] = useState([{label: "Class", options: [{label:'Select Class',value:null}]}]);
  const [error_alert, seterror_alert] = useState(false);

  const getData = async ()=> {
    setIsloading(true);
    try {
      let response = await get("/student/all/data");
      let rawData = response.data;
      let data = rawData.map((item,index)=>{
          const jenis = item.allow_pickup ? "In Charge" : "Online";
          const keyExtractor = `${item.va}-${item.name}-${item.kelas}-${jenis}`.toLowerCase();
          return {va: item.va, name: item.name, kelas: item.kelas, jenis: jenis, keyExtractor: keyExtractor}
      })
      setStudents(data || []);
      setStudentFiltered(data || []);
      setFilterData(data);
    } catch (error) {
      seterror_alert(true);
    }
    setIsloading(false);
  }

  const setFilterData = raw => {
    const jenis = raw.map(item => item.jenis).filter((value,i,array) => array.indexOf(value) === i)
    const kelas = raw.map(item => item.kelas).filter((value,i,array) => array.indexOf(value) === i)

    let jenisFilterOptions = [{label:'Select Status',value:null}]
    let classFilterOptions = [{label:'Select Class',value:null}]

    jenis.map(item => {
      jenisFilterOptions.push({label:item,value:item})
    });
    kelas.map((item, index) => {
      classFilterOptions.push({label:item,value:item})
    })

    let jenisFilter = {
      label:"status",
      options:jenisFilterOptions
    }
    let classFilter = {
      label:"class",
      options:classFilterOptions
    }
    setJenis([jenisFilter]);
    setKelas([classFilter]);
  }

  const clearFilter = () => {
    setFilter({jenis:null, kelas:null, key:''});
  }

  useEffect (()=>{
    getData();
  },[])

  useEffect(()=>{
    setCurrentPage(1);
    const newData = students.filter(item => {
      return ((filter?.kelas) ? (filter?.kelas === item.kelas) : true) && ((filter?.jenis) ? (filter?.jenis === item.jenis) : true) && 
      ((filter?.key) ? (item.keyExtractor.includes(filter.key.toLowerCase())) : true)
    })
    setStudentFiltered(newData)
  },[filter])

  const header = ["VA","Student Name","Class","Status"];

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = studentsFiltered.slice(indexOfFirstData, indexOfLastData);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tzu Chi</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Attendance")}
            breadcrumbItem={props.t("Information")}
          />

          <Card>
            <CardBody>
              <Row>
                <h5>Attendance Information</h5>
              </Row>

              <Row className="mb-2">
              <Col md="2">
                  <div className="my-2">
                    <Select
                      placeholder="Select Class"
                      value={kelas[0].options.find(obj => obj.value === filter.kelas)}
                      options={kelas}
                      onChange={item => setFilter({...filter,kelas:item.value})}
                    />
                  </div>
                </Col>
                <Col md="2">
                  <div className="my-2">
                    <Select
                      placeholder="Select Status"
                      value={jenis[0].options.find(obj => obj.value === filter.jenis)}
                      options={jenis}
                      onChange={item => setFilter({...filter,jenis:item.value})}
                    />
                  </div>
                </Col>
                <Col md="2">
                  <div className="my-2">
                    <Button color="primary" className=" px-4" onClick={()=>clearFilter()}>Clear</Button>
                  </div>
                </Col>
                <Col md="6" className="mt-2">
                  <div className="search-box me-2 mb-2 d-flex justify-content-end">
                    <div className="position-relative ">
                      <input className="form-control" type="text" placeholder="Search" value={filter.key} onChange={item=> setFilter({...filter,key:item.target.value})}/>
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className='table-responsive'>
                <Table className='table'>
                    <thead>
                        <tr>
                          {header.map((x, index) => (
                            <th key={`head-${index}`}>{x}</th>
                          ))}
                        </tr>
                    </thead>
                    <tbody>
                      {isLoading ?
                        <tr>
                            <td colSpan={4}><LoadingSpinner/></td>
                        </tr>
                        :
                        currentData.length > 0 ?
                        currentData.map((e,index) => {
                            return(
                              <React.Fragment key={`${e.id}-${index}`}>
                                <tr>
                                  <td>{e?.va || "N/A"}</td>
                                  <td>{e?.name || "N/A"}</td>
                                  <td>{e?.kelas || "N/A"}</td>
                                  <td><Badge className={`bg-${e.jenis == "Online" ? "info" :  "secondary"}`}>{e.jenis}</Badge></td>
                                </tr>
                              </React.Fragment>
                            )
                          })
                        :
                        <tr>
                          <td colSpan={4} className="text-center">No Data</td>
                        </tr>
                      }
                    </tbody>
                </Table>
              </div>
              
              <Pagination
                setDataPerPage={setDataPerPage}
                dataPerPage={dataPerPage}
                totalData={studentsFiltered.length}
                paginate={paginate}
                currentPage={currentPage}
              />

            </CardBody>
          </Card>
        </Container>
      </div>

      { error_alert ?
          <SweetAlert
              title="Oppss!"
              confirmBtnText="Yes"
              error
              confirmBtnBsStyle="primary"
              onConfirm={() => {
                  seterror_alert(false)
              }}
          >
              Something wrong, please try again
          </SweetAlert>
      : null }
    </React.Fragment>
  );
};

Information.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Information);
