import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Badge,
  Button,
  Card,
  CardBody,
  Table,
  Spinner,
} from "reactstrap";

import Select from "react-select";
import { Link } from "react-router-dom";
import { get, post } from "helpers/api_helper";
import Flatpickr from "react-flatpickr";
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { forEach } from "lodash";
import LoadingSpinner from "components/Common/LoadingSpinner";
import NoData from "components/Common/NoData";
import { API_URL } from "helpers/url_helper";
import Pagination from "pages/Master-Data/Component/Student/Pagination";

const Laporan = props => {
  const todayDate = moment().format(`YYYY-MM-DD`);
  // const tommorow = moment().add('days',1).format(`YYYY-MM-DD`);
  // const yesterday = moment().subtract('days',1).format(`YYYY-MM-DD`);

  const [ report,setReport ] = useState([]);
  const [ startDate, setStartDate ] = useState(todayDate);
  const [ endDate, setEndDate ] = useState(todayDate);
  const [ fetch,setFetch] = useState(false);
  
  const [ classes, setClasses] = useState([{label: "mark", options: [{label:'Select Mark',value:null}]}]);
  const [ mark, setMark ] = useState([{label: "class", options: [{label:'Select Class',value:null}]}]);
  const [ reportFiltered, setReportFiltered ] = useState([]);
  const [ filter,setFilter ] = useState({classes:null,mark:null,key:''});
  const [ isLoading, setIsloading ] = useState(false);
  const [ isDownload, setIsDownload ] = useState(false);
  const [ expand, setExpand ] = useState([]);
  const [ expandLoading, setExpandLoading ] = useState(false);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ dataPerPage, setDataPerPage ] = useState(10);
  const [error_alert, seterror_alert] = useState(false);

  const getData = async ()=> {
    setIsloading(true);
    try{
      let response = await get(`/pickup/log/date?start_date=${startDate}&end_date=${endDate}`);
      let date = Object.keys(response.data)
      let newDate = [];
      for (const [key,value] of Object.entries(response.data)){
        let pickup = value.map(item => {
          item.tanggal = moment(item.updated_at).format(`DD/MM/YYYY`)
          return item
        })
        pickup.forEach(element=> newDate.push(element))
      }
      newDate.sort((a,b)=> moment(b.tanggal).isAfter(a.tanggal)?1:-1)
      setReport(recycleData(newDate));
      setReportFiltered(recycleData(newDate) || []);
      setFilterData(recycleData(newDate))
    } catch (error) {
      seterror_alert(true);
    }
    setIsloading(false)
  }

  const recycleData = data => {
   const newData =  data.map(item => {
      let result = {};
      result.id = item.id
      result.name = item?.student?.name || "N/A"
      result.kelas = item?.student?.kelas || "N/A"
      result.class_id = item?.student?.class_id || "N/A"
      result.va = item?.student?.va || "N/A"
      result.fetcher = item?.driver?.full_name || "N/A"
      result.teacher = item?.release_teacher?.full_name || "N/A"
      result.mark = item?.action || "N/A"
      result.date = item?.tanggal || "N/A"
      result.key = `${item.student.va}-${item.tanggal}`
      result.timeline = [
        {actor:item?.driver?.full_name || "Fetcher" , time:item.start_date, icon:"bx-barcode", desc:"Pickup request are made",fade:false,color:"primary"},
        (item.end_date) ?
        {actor:item?.release_teacher?.full_name || "Teacher", time:item.end_date, icon:"bx-user",desc:"Releasing student",fade:false,color:"primary"}
        :
        {actor:item?.driver?.full_name || "Fetcher", time:item.due_date, icon:"bx-timer",desc:"QR Expired",fade:true,color:"danger"}

      ]
      result.isExpand = false
      result.keyExtractor = `${result.va}-${result.name}-${result.kelas}-${result.fetcher}-${result.teacher}-${result.mark}-${result.date}`.toLowerCase()
      return result;
    });
    return newData;
  }

  const setFilterData = raw => {
    raw.map(item => {
      if(item.mark === 'QRCode Release') { item.mark = 'QR Code' }
      else if(item.mark === 'Manual Release') { item.mark = 'Manual Release' }
      else { item.mark = 'Canceled' }
    });

    const mark = raw.map(item => item.mark).filter((value,i,array) => array.indexOf(value) === i)
    const kelas = raw.map(item => item.kelas).filter((value,i,array) => array.indexOf(value) === i)
    const class_id = raw.map(item => item.class_id).filter((value,i,array) => array.indexOf(value) === i)

    let markFilterOptions = [{label:'Select Mark',value:null}]
    let classFilterOptions = [{label:'Select Class',value:null}]

    mark.map(item => {
      markFilterOptions.push({label:item,value:item})
    });
    kelas.map((item, index) => {
      classFilterOptions.push({label:item,value:class_id[index]})
    })

    let markFilter = {
      label:"mark",
      options:markFilterOptions
    }
    let classFilter = {
      label:"class",
      options:classFilterOptions
    }
    setMark([markFilter]);
    setClasses([classFilter]);
  }

  const clearFilter = () => {
    setFilter({mark:null,classes:null,key:''});
  }

  const generateDownload = async ()=> {
    if(!isDownload){
      setIsDownload(true)
      
      // &status=accepted&class_id=1
      let url = `/pickup/log/date?start_date=${startDate}&end_date=${endDate}&download=excel`
      if(filter.classes !== null) {
        url += `&class_id=${filter.classes}`;
      }
      console.log(url);
      try {
        let response = await get(url);
        window.open(API_URL + "/" + response.data.url,"_blank")
        setIsDownload(false)
      } catch (error) {
        seterror_alert(true);
        setIsDownload(false)
      }
      
    }
    else {
      console.log("Sibuk");
    }
  }

  useEffect (()=>{
    getData();
  },[fetch])

  useEffect(()=>{
    setCurrentPage(1);
    let mark = 'N/A'
    if(filter.mark) {
      if(filter.mark === 'QR Code') { mark = 'QRCode Release' }
      else if(filter.mark === 'Manual Release') { mark = 'Manual Release' }
    }
    const newData = report.filter(item => {
      return ((filter?.mark) ? (mark === item.mark) : true) && ((filter?.classes) ? (filter?.classes === item.class_id) : true) && 
      ((filter?.key) ? (item.keyExtractor.includes(filter.key.toLowerCase())) : true)
    })
    setReportFiltered(newData)
  },[filter])

  const handleExpandFetcher = (id) => {
    const newData = reportFiltered.map(e=>{
      if(e.id === id){
          e.isExpand = !e.isExpand;
      } else {
          e.isExpand = false;
      }
      return e
    });
    setReportFiltered(newData);
  }

  const header = ["VA","Student Name","Classes","Fetcher","Teacher","Mark","Date","Action"];

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = reportFiltered.slice(indexOfFirstData, indexOfLastData);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tzu Chi</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Pickup")}
            breadcrumbItem={props.t("Report")}
          />
          <Card>
            <CardBody>
              <Row>
                <h5>Pickup Report</h5>
              </Row>

              <Row className="mb-2">
                <Col md="2">
                  <div className="my-2">
                    <Flatpickr
                    className="form-control d-block"
                    placeholder="Start date"
                    options={{
                      dateFormat:`d/m/Y`,
                      maxDate:`today`
                    }}
                    onChange={(e)=>setStartDate(moment(new Date(e)).format(`YYYY-MM-DD`))}
                    />
                    {/* <input
                      className="form-control"
                      type="date"
                      defaultValue="2019-08-19"
                      id="example-date-input"
                    /> */}
                  </div>
                </Col>
                <Col md="2">
                  <div className="my-2">
                  <Flatpickr
                    className="form-control d-block"
                    placeholder="End date"
                    options={{
                      dateFormat:`d/m/Y`,
                      maxDate:`today`,
                    }}
                    onChange={(e)=>setEndDate(moment(new Date(e)).format(`YYYY-MM-DD`))}
                    />
                  </div>
                </Col>
                <Col md="2">
                  <div className="my-2">
                    <Button color="primary" className="px-4" onClick={()=>setFetch(!fetch)}>Apply</Button>
                  </div>
                </Col>
                <Col md="6" className="mt-2">
                  <div className="search-box me-2 mb-2 d-flex justify-content-end">
                    <div className="position-relative ">
                      <input className="form-control" type="text" placeholder="Search" value={filter.key} onChange={item=> setFilter({...filter,key:item.target.value})}/>
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="2">
                  <div className="my-2">
                    <Select
                      placeholder="Select Class"
                      value={classes[0].options.find(obj => obj.value === filter.classes)}
                      options={classes}
                      onChange={item => setFilter({...filter,classes:item.value})}
                    />
                  </div>
                </Col>
                <Col md="2">
                  <div className="my-2">
                    <Select
                      placeholder="Select Mark"
                      value={mark[0].options.find(obj => obj.value === filter.mark)}
                      options={mark}
                      onChange={item => setFilter({...filter,mark:item.value})}
                    />
                  </div>
                </Col>
                <Col md="2">
                  <div className="my-2">
                    <Button color="primary" className=" px-4" onClick={()=>clearFilter()}>Clear</Button>
                  </div>
                </Col>
                <Col md="6" className="mt-2">
                  <div className="d-flex justify-content-end">
                    <Button color="success" className="btn" onClick={()=>generateDownload()} disabled={reportFiltered.length==0?true:false}>{
                      isDownload ? 
                      <>
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Loading
                      </> :
                      `Generate Report`
                    }</Button>
                  </div>
                </Col>
              </Row>

              <div className='table-responsive'>
                  <Table className='table'>
                      <thead>
                          <tr>
                            {header.map((x, index) => (
                              <th key={`head-${index}`}>{x}</th>
                            ))}
                          </tr>
                      </thead>
                      <tbody>
                        {currentData.length > 0 ?
                          isLoading ? 
                            <tr>
                                <td colSpan={8}><LoadingSpinner/></td>
                            </tr>
                          : 
                          currentData.map((e,index) => {
                              return(
                                <React.Fragment key={`${e.id}-${index}`}>
                                  <tr>
                                    <td>{e?.va || "N/A"}</td>
                                    <td>{e?.name || "N/A"}</td>
                                    <td>{e?.kelas || "N/A"}</td>
                                    <td>{e?.fetcher || "N/A"}</td>
                                    <td>{e?.teacher || "N/A"}</td>
                                    <td>
                                      { 
                                        e.mark === `QRCode Release` ?
                                        <Badge className="bg-success py-1">QR Code</Badge> :
                                        e.mark === `Manual Release` ?
                                        <Badge className="bg-info py-1">Manual Release</Badge> :
                                        <Badge className="bg-danger py-1">Canceled</Badge> 
                                      }
                                    </td>
                                    <td>{e?.date || "N/A"}</td>
                                    <td>
                                      <Button color="secondary" className="btn btn-sm" onClick={()=>handleExpandFetcher(e.id)}>
                                        <i className={`bx bx-chevron-${e.isExpand ? "down" : "right"}`}/>
                                      </Button> 
                                    </td>
                                  </tr>
                                  {e.isExpand ? 
                                    <tr>
                                      <td colSpan={6}>
                                        {
                                          expandLoading ?
                                          <LoadingSpinner/>
                                        :
                                          <div className="p-2">
                                            <ul className="verti-timeline list-unstyled">
                                              {e.timeline.map((item, key) => {
                                                return(
                                                  <li key={key} className="event-list">
                                                    <div className="event-timeline-dot">
                                                      <i className={`bx bx-right-arrow-circle ${item.fade?"bx-fade-right":null}`}/>
                                                    </div>
                                                    <div className="d-flex">
                                                    <div className="me-3">
                                                      <i className={`bx ${item.icon} h2 text-${item.color}`}/>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                      <div>
                                                        <h5>{item.actor}</h5>
                                                        <p className="text-muted my-0">
                                                          {moment(item.time).format(`HH:mm - DD/MM/YYYY`)}
                                                        </p>
                                                        <p className="text-muted">
                                                          {item.desc}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    </div>
                                                  </li>
                                                )
                                              })}
                                            </ul>
                                          </div>
                                        }
                                      </td>
                                    </tr>
                                  : null}
                                </React.Fragment>
                              )
                            })
                          :
                          <tr>
                            <td colSpan={8} className="text-center">No Student</td>
                          </tr>
                        }
                      </tbody>
                  </Table>
                </div>
                <Pagination
                  setDataPerPage={setDataPerPage}
                  dataPerPage={dataPerPage}
                  totalData={reportFiltered.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />

            </CardBody>
          </Card>
        </Container>
      </div>

      { error_alert ?
        <SweetAlert
          title="Oppss!"
          confirmBtnText="Yes"
          error
          confirmBtnBsStyle="primary"
          onConfirm={() => {
              seterror_alert(false)
          }}
        >
            Something wrong, please try again
        </SweetAlert>
      : null }
    </React.Fragment>
  );
};

Laporan.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Laporan);
