import React, {useState, useEffect} from 'react';
import PropTypes, { array } from 'prop-types'
import { Pagination as Page, PaginationItem, PaginationLink, Row, Col } from 'reactstrap';
import Select from "react-select";

const Pagination = ({ dataPerPage, setDataPerPage, totalData, paginate, currentPage }) => {
  const [rawPageNum,setRawPageNumber] = useState([])
  const [pageNumbers,setPageNumbers] = useState([]);
  const pageNeighboor = 3;
  let dataOptions = [
    {label: '10', value: 10},
    {label: '25', value: 25},
    {label: '50', value: 50},
    {label: '100', value: 100},
];
  useEffect(()=>{
    let rawPageNum = []
    let filteredPageNum = []
    for (let i = 1; i <= Math.ceil(totalData / dataPerPage); i++) {
      rawPageNum.push(i);
    }
    filteredPageNum = rawPageNum;
    if(rawPageNum.length >  5){
      if(currentPage > pageNeighboor){
        filteredPageNum = rawPageNum.filter(x => x > currentPage - pageNeighboor && x < currentPage + pageNeighboor)
      }
      else {
        filteredPageNum = rawPageNum.filter(x => x <= 5)
      }

    }
    setRawPageNumber(rawPageNum)
    setPageNumbers(filteredPageNum)
  },[currentPage,totalData,dataPerPage])

  return (
    <>
      <Row>
        <Col md={2}>
          <Select
              value={dataOptions.find(obj => obj.value === dataPerPage)}
              onChange={(e) => {setDataPerPage(e.value); paginate(1)}}
              options={dataOptions}
              classNamePrefix="select2-selection"
              menuPlacement="auto"
          />
          <p className='text-muted mb-1 mt-2'>Page : {currentPage} / {rawPageNum[rawPageNum.length-1]}</p> 
          <p className='text-muted'> Total Data : {totalData}</p>
        </Col>
        <Col md={10} className="d-flex justify-content-end">
          <Page>
            {
              currentPage > 1 ?
              <>
                <PaginationItem>
                  <PaginationLink onClick={()=> paginate(currentPage - 1)}><i className='bx bx-chevron-left'/></PaginationLink>
                </PaginationItem>
              </>
              : null
            }
            {
              !pageNumbers.includes(1) ? 
              <>
              <PaginationItem>
                <PaginationLink onClick={()=> paginate(1)}><i className='bx bx-chevrons-left'/></PaginationLink>
              </PaginationItem>
            </>
            : null
            }
              {pageNumbers.map(number => {
                 return (
                 <React.Fragment key={`pagintaion-${number}`}>
                  <PaginationItem className={number === currentPage ? `active` : null}>
                    <PaginationLink onClick={()=> paginate(number)}>{number}</PaginationLink>
                   </PaginationItem>
                </React.Fragment>
               )
            })}
            {
              !pageNumbers.includes(rawPageNum.length) ? 
              <>
              <PaginationItem>
                <PaginationLink onClick={()=> paginate(rawPageNum.length)}><i className='bx bx-chevrons-right'/></PaginationLink>
              </PaginationItem>
            </>
            : null
            }
            {
              currentPage < rawPageNum.length ?
              <>
                <PaginationItem>
                  <PaginationLink onClick={()=> paginate(currentPage + 1)}><i className='bx bx-chevron-right'/></PaginationLink>
                </PaginationItem>
              </>
              : null
            }
          </Page>
        </Col>
      </Row>
    </>
  );
};

Pagination.propTypes = {
    dataPerPage : PropTypes.any,
    totalData : PropTypes.any,
    paginate : PropTypes.any,
    currentPage : PropTypes.number, 
    setDataPerPage: PropTypes.any
}
export default Pagination;
