import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal, 
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert"

import { get, post} from "helpers/api_helper";

//i18n
import { withTranslation } from "react-i18next";

const ModalDelegasiTeacher = (props) => {
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [message_alert, setmessage_alert] = useState(false);
    const [ error_alert, seterror_alert ] = useState(false);
    const [selectErr, setSelectErr] = useState({text: false, alert: false});
    const [selectedGroup, setselectedGroup] = useState(null);
    const [classData, setClassData] = useState([]);
   
    const getDataClass = async () => {
        try {
            let response = await get(`/class/all?teacher_id=${props.dataTeacher.id}`);
            let data = response.data;
            const map = data.map((item) => {
                return { label: item.name, value: item.id }
            });
            setClassData(map);
        } catch (error) {
            seterror_alert(true);
        }
    }

    let dataOptions = [{label: 'choose class', value: null}];
    classData.map((item) => {
        dataOptions.push(item);
    });

    useEffect(() => {
        if(props.dataTeacher.id !== null) { getDataClass() }
        else { setselectedGroup(null) }
    }, [props.modalShow]);

    function handleSelectGroup(e) {
        if(e.value == null) { setSelectErr({...selectErr, text: true}) }
        else { setSelectErr({...selectErr, text: false}) }
        setselectedGroup(e.value);
    }

    const postDelegasi = async () => {
        const body = {
            class_id: selectedGroup,
            teacher_id: props.dataTeacher.id
        };

        try {
            let response = await post(`/class/add_teacher_temporary`, body);
            if(response) {
                setselectedGroup(null);
                getDataClass();
                setmessage_alert(true);
            }
        } catch (error) {
            seterror_alert(true);
        }
    }

    const handlerSubmit = () => {
        if(selectedGroup === null) {
            setSelectErr({...selectErr, text: true, alert: true});
        }
        else {
            setconfirm_alert(true);
        }
    }

    return(
        <div>
            <Modal
                size="lg"
                centered={true}
                isOpen={props.modalShow}
                toggle={props.toggleModal}
            >
                <ModalHeader toggle={props.toggleModal}>Teacher Delegation</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg='3'><Label>Teacher Name</Label></Col>
                        <Col lg='6'><Label>: {props.dataTeacher.name}</Label></Col>
                    </Row>
                    <Row className="mb-3">
                        <Label className="col-md-3 col-form-label">Choose Class</Label>
                        <div className="col-md-6">
                            <Select
                                value={dataOptions.find(obj => obj.value === selectedGroup)}
                                onChange={(e) => {
                                    handleSelectGroup(e);
                                }}
                                options={dataOptions}
                                classNamePrefix="select2-selection"
                            />
                            { selectErr.text ?
                                <span className="text-danger">This field is required, please choose the class</span>
                            : null }
                        </div>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-row justify-content-end">
                                <button className="btn btn-sm btn-primary mx-1" onClick={handlerSubmit}>Submit</button>
                                <button className="btn btn-sm btn-danger" onClick={props.toggleModal}>Cancel</button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            
            { confirm_alert ? 
                <SweetAlert
                    title= 'Warning!'
                    info
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        postDelegasi();
                        setconfirm_alert(false);
                    }}
                    onCancel={() => {setconfirm_alert(false);}}
                >
                    Are you sure to delegate the teacher
                </SweetAlert>
            : null }

            { message_alert ?
                <SweetAlert
                    title="Success!"
                    confirmBtnText="Yes"
                    success
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        setmessage_alert(false);
                        props.toggleModal();
                    }}
                >
                    Success to delegate teacher
                </SweetAlert>
            : null }

            { selectErr.alert ?
                <SweetAlert
                    title="Upsss!"
                    confirmBtnText="Yes"
                    danger
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        setSelectErr({...selectErr, alert: false});
                    }}
                >
                    Please select a class first
                </SweetAlert>
            : null }

            { error_alert ?
                <SweetAlert
                    title="Oppss!"
                    confirmBtnText="Yes"
                    error
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        seterror_alert(false)
                    }}
                >
                    Something wrong, please try again
                </SweetAlert>
            : null }
        </div>
    )
}

ModalDelegasiTeacher.propTypes = {
    toggleModal: PropTypes.any,
    modalShow: PropTypes.any,
    dataTeacher: PropTypes.any,
};

export default withTranslation()(ModalDelegasiTeacher);