import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Table,
    CardTitle,
    Button,
    Badge,
} from "reactstrap";
import { get } from "helpers/api_helper";
import SweetAlert from "react-bootstrap-sweetalert";

//i18n
import { withTranslation } from "react-i18next";
import ModalDelegasiTeacher from "./ModalDelegasiTeacher";
import Pagination from "pages/Master-Data/Component/Student/Pagination";
import LoadingSpinner from "components/Common/LoadingSpinner";

//redux
// import { useSelector, useDispatch } from "react-redux";

const Guru = props => {
    const [modal, setmodal] = useState(false);
    const [teacherData, setTeacherData] = useState({id: null, name: ''});
    // Table Data
    const [ teacher, setTeacher ] = useState([]);
    const [ teacherFiltered, setTeacherFiltered ] = useState([]);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ dataPerPage, setDataPerPage ] = useState(10);
    const [ filter,setFilter ] = useState({key:''});
    const [ isLoading, setIsloading ] = useState(false);
    const [ error_alert, seterror_alert ] = useState(false);

    const getData = async ()=> {
      setIsloading(true);
      try {
        let response = await get("/teacher");
        let data = response.data;
        const map = data.map((item, index) => {
            let id = item.id
            let namaGuru = item.full_name;
            let kelas = '-';
            let status = 'Partner';
            if(item.main_class !== null) {
                kelas = item.main_class.class.name;
                status = 'Form Teacher';
            }
            let keyExtractor = `${namaGuru}-${kelas}-${status}`.toLowerCase();
            return { id: id, temporary: item.temoray_class.length, namaGuru: namaGuru, kelas: kelas, status: status, keyExtractor: keyExtractor };
        });
        const sortedData = map.sort((a,b)=>a.id - b.id);
        setTeacher(sortedData);
        setTeacherFiltered(sortedData);
      } catch (error) {
        seterror_alert(true);
      }
      setIsloading(false);
    }

    useEffect (()=>{
      getData()
    }, []);

    useEffect(()=>{
        setCurrentPage(1);
        const newData = teacher.filter(item => {
          return ((filter?.key) ? (item.keyExtractor.includes(filter.key.toLowerCase())) : true);
        })
        setTeacherFiltered(newData)
    },[filter]);

    const toggleModal = (id = null, name = '') => {
        if(!modal) { setTeacherData({id: id, name: name}) }
        else { setTeacherData({id: null, name: ''}) }
        setmodal(!modal);
    }

    const header = ["Teacher Name","Class","Status","Action"];

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = teacherFiltered.slice(indexOfFirstData, indexOfLastData);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return ( 
        <div>
            <Row>
                <Col className="col-12">
                    <CardTitle className="h4">Teacher Delegation</CardTitle>
                    <Row className="my-2">
                      <Col md="12" className="mt-2">
                        <div className="search-box me-2 mb-3 d-flex justify-content-end">
                          <div className="position-relative ">
                            <input className="form-control" type="text" placeholder="Search" value={filter.key} onChange={item=> setFilter({...filter,key:item.target.value})}/>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className='table-responsive'>
                      <Table className='table'>
                        <thead>
                            <tr>
                              {header.map((x, index) => (
                                <th key={`head-${index}`}>{x}</th>
                              ))}
                            </tr>
                        </thead>
                        <tbody>
                          {currentData.length > 0 ?
                            isLoading ? 
                              <tr>
                                  <td colSpan={4}><LoadingSpinner/></td>
                              </tr>
                            : 
                            currentData.map((e,index) => {
                                return(
                                  <React.Fragment key={`${e.id}-${index}`}>
                                    <tr>
                                      <td>{e?.namaGuru || ""}</td>
                                      <td>{e?.kelas || ""}</td>
                                      <td>
                                        <Badge className={`bg-${e.status == 'Partner' ? 'secondary' : 'success'} py-1`}>{e?.status || ""}</Badge>
                                      </td>
                                      <td>
                                        {e.temporary == 0 ?
                                            <Button className="btn btn-sm btn-primary" onClick={() => toggleModal(e.id, e.namaGuru)}>Delegate</Button>
                                        : 
                                            <Badge className="bg-info py-1">On Delegation</Badge>
                                        }  
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              })
                            :
                            <tr>
                              <td colSpan={4} className="text-center">No Data</td>
                            </tr>
                          }
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      setDataPerPage={setDataPerPage}
                      dataPerPage={dataPerPage}
                      totalData={teacherFiltered.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                </Col>
            </Row>

            <ModalDelegasiTeacher modalShow={modal} toggleModal={toggleModal} dataTeacher={teacherData} />
            { error_alert ?
                <SweetAlert
                    title="Oppss!"
                    confirmBtnText="Yes"
                    error
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        seterror_alert(false)
                    }}
                >
                    Something wrong, please try again
                </SweetAlert>
            : null }
        </div>
    );
};

Guru.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(Guru);
